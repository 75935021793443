.centered{
  display: flex;
  justify-content: center;
  align-items: center;
}

.image{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.editBox{
  background-color:rgba(0, 0, 0, 0.466);
  border:2px solid aliceblue;
  border-radius: 8px;
  position: relative;
  padding:2px;
}

input[type="file"] {
  display: none;
}
.custom-file-upload {
  background-color:aliceblue;
  border:2px solid blue;
  border-radius: 8px;
  display: inline-block;
  padding: 0px 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submitButton {
  margin-left:"10px";
  margin-right:"10px";
}